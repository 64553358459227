import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NextSeo, BreadcrumbJsonLd, ProductJsonLd } from 'next-seo';
import Head from 'next/head';
import constants from '../../config/constants';
import WithUrl from '../WithUrl';
import getImages from '../GetImages';
import _ from '../LodashImports';
import { getPriceToNumber, PRICE_TYPES } from '../PriceHelpers';

const mainPriceOrder = [
  PRICE_TYPES.eventPrice,
  PRICE_TYPES.internetPrice,
  PRICE_TYPES.normalPrice,
  PRICE_TYPES.cmrPrice
];
const secondaryPriceOrder = [...mainPriceOrder].reverse();

const getBreadcrumbData = (region, store, breadcrumb = []) => {
  const regionName = store === 'so_com' ? `${region}_${store}` : region;
  const host = WithUrl('FULL_HOST', regionName);
  return breadcrumb.map((e, i) => {
    return {
      position: i,
      name: e.label,
      item: `${host}${e.link}`
    };
  });
};

const offerBuilder = (input) => (price, currentPrice) => {
  const { prices, sku, regionCode, ...metadata } = input;

  if (price) {
    const formattedPrice = String(getPriceToNumber(price.price, regionCode));
    return { ...price, price: formattedPrice };
  }

  const priceType = prices.find((p) => p.type === currentPrice);
  if (!priceType) return price;

  const result = {
    ...metadata,
    price: priceType.price[0],
    ...(priceType.type !== PRICE_TYPES.cmrPrice && { sku })
  };

  return result;
};

const getOffers = ({
  variants,
  currentVariantId,
  sellerName,
  currency,
  isOutOfStock,
  regionCode = 'cl'
}) => {
  const currentVariant = variants.find((e) => e.id === currentVariantId);
  if (!currentVariant) return [];
  const { prices } = currentVariant;
  const metadata = {
    regionCode,
    priceCurrency: currency,
    availability: isOutOfStock
      ? 'https://schema.org/OutOfStock'
      : 'https://schema.org/InStock',
    seller: {
      name: sellerName
    },
    prices,
    sku: currentVariantId
  };
  const builder = offerBuilder(metadata);
  const mainPrice = mainPriceOrder.reduce(builder, undefined); // normal price

  const secondaryPrice = secondaryPriceOrder.reduce(builder, undefined); // cmr price

  return [mainPrice, secondaryPrice];
};
const checkSLTProduct = (sellerName) => {
  return (
    constants.SODIMAC_REGEX.test(sellerName) ||
    constants.TOTUS_REGEX.test(sellerName) ||
    constants.LINIO_REGEX.test(sellerName)
  );
};

const getMetaDataForCO = (type, { name, brandName }, region) => {
  const metaTemplate = constants.PDP.META[region.toUpperCase()][type];

  return metaTemplate
    .replace(/\$product_name\$/g, name)
    .replace(/\$brand_name\$/g, brandName);
};

const PDPSeo = ({ siteProps }) => {
  const {
    regionCode = 'cl',
    product,
    breadcrumb = [],
    variants,
    openGraphData,
    appCtx
  } = siteProps;
  const { store } = appCtx;
  const isRegionCO = regionCode === 'co';
  const title = isRegionCO
    ? getMetaDataForCO('TITLE', product, regionCode)
    : openGraphData.title;
  const description = isRegionCO
    ? getMetaDataForCO('DESCRIPTION', product, regionCode)
    : openGraphData.description;
  const updatedOpenGraphData = isRegionCO
    ? {
        ...openGraphData,
        title,
        description
      }
    : openGraphData;
  const breadcrumbData = getBreadcrumbData(regionCode, store, breadcrumb);
  const { variantId } = product;
  const primaryVariant = variants.find(({ id }) => {
    return id === variantId;
  });
  const sellerName = _.get(
    product,
    'variants[0].offerings[0].sellerName',
    ''
  ).toLowerCase();

  const isCFMediasEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isCFMediasEnabled',
    false
  );
  const mediaUrl = _.get(primaryVariant, 'medias[0].url');
  const imageUrl = getImages({
    variantId: `${product.variantId}_1`,
    isCFMediasEnabled,
    sellerName,
    regionCode,
    url: mediaUrl
  });
  const indexAndCrawlSLTPagesToggle = _.get(
    appCtx,
    'siteConfig.toggles.indexAndCrawlSLTPagesToggle',
    false
  );
  const isSLTProduct = checkSLTProduct(product.sellerName);
  return (
    <Fragment>
      <Head>
        <title> {title} </title>
      </Head>
      <NextSeo
        noindex={isSLTProduct && !indexAndCrawlSLTPagesToggle}
        canonical={openGraphData.canonicalUrl}
        description={description}
        openGraph={updatedOpenGraphData}
      />
      <BreadcrumbJsonLd itemListElements={breadcrumbData} />
      <ProductJsonLd
        id={updatedOpenGraphData.url}
        productName={JSON.stringify(product.name)}
        sku={product.variantId}
        images={[imageUrl]}
        description={JSON.stringify(product.description)}
        brand={product.brandName}
        offers={getOffers({
          currency: constants.CURRENCY[regionCode.toUpperCase()],
          currentVariantId: product.variantId,
          isOutOfStock: product.isOutOfStock,
          sellerName: store === 'so_com' ? 'Sodimac' : 'Falabella',
          variants,
          regionCode
        })}
      />
    </Fragment>
  );
};

PDPSeo.propTypes = {
  siteProps: PropTypes.object.isRequired
};

export { getBreadcrumbData, getOffers, offerBuilder };
export default PDPSeo;
